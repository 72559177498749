<template>
  <div class="container">
    <div class="h5 main title-wrapper">북마크</div>
    <div class="product-list-wrapper">
      <card-product-temp
        v-for="card in list"
        :key="`card-pc-${card.id}`"
        :card="card"
        :device="isMobile ? 'mobile' : 'pc'"
      ></card-product-temp>
    </div>
    <div v-if="list && list.length === 0" class="empty-view2 text-center sub3">북마크 상품이 없습니다</div>
    <!-- 페이지네이션 -->
    <lp-paginate v-if="list.length > 0" :filtered="tempFiltered" @getData="getData" class="margin-top-40"></lp-paginate>
  </div>
</template>

<script>
import UserAPIMixin from '../../mixins/UserAPIMixin';
import CardProductTemp from '../component/CardProductTemp';
import LpPaginate from '../component/LpPaginate';

export default {
  name: 'MyBookmark',
  components: {
    CardProductTemp,
    LpPaginate,
  },
  mixins: [UserAPIMixin],
  created() {
    this.getData();
  },
  data() {
    return {
      list: [],
      tempFiltered: {
        cur_page: 1,
        page_num: 1,
        total_page: 1,
        page_length: this.isMobile ? 16 : 20,
        ordering: '-id',
        category: '58',
        liked_user: this.$store.state.user.user_id,
        field:
          'id,name,simple_desc,price,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,params,theme_version,tags,techs,is_liked',
      },
    };
  },
  computed: {
    setRouterViewBackground() {
      return () => {
        const routerView = document.getElementsByClassName('router-view')[0];
        if (routerView) {
          routerView.style.backgroundColor = '#ffffff';
        }
      };
    },
  },
  methods: {
    getData() {
      this.$store.commit('setLoading', true);
      this.$axios
        .get(`user/${this.$store.state.user.user_id}/mapping/product`, { params: this.tempFiltered })
        .then((res) => {
          this.list = res.data.data;
          Object.assign(this.tempFiltered, {
            page_length: res.data.page_length,
            cur_page: res.data.cur_page,
            total_page: res.data.total_page,
          });
          this.$store.commit('setLoading', false);
        });
    },
  },
  mounted() {
    this.setRouterViewBackground();
  },
};
</script>

<style lang="stylus" scoped>
.title-wrapper
  padding-top 40px
  padding-bottom 24px
  background-color #fff
.product-list-wrapper
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 48px 24px
  width 1152px
  background-color #fff

@media (max-width: 425px)
  .product-list-wrapper
    display grid
    grid-template-columns repeat(1, 1fr)
    gap 24px
    width 100%
</style>
